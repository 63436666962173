//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'privateSea',
  data: function data() {
    return {
      quaflat: 1
    };
  },
  mounted: function mounted() {
    this.privateSea(1);
  },
  methods: {
    privateSea: function privateSea(item) {
      this.quaflat = item;
      this.$emit('tiemOption', item);
    }
  }
};